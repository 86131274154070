const TaskTimer = ({ activeTask, timers }) => {
    const formatTime = (seconds) => {
        const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
        const secs = String(seconds % 60).padStart(2, '0');
        return `${hours}:${minutes}:${secs}`;
    };

    return (
        <div>
            {activeTask && (
                <p>🕔 {formatTime(timers[activeTask.id] || 0)}</p>
            )}
        </div>
    );
};

export default TaskTimer;
