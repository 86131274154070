import React, { useState, useEffect, useRef } from 'react';
import axios from './modules/axiosInstance';
import { toast } from 'react-toastify';
import './styles/Workspace.css';
import CreateTaskForm from './connectables/CreateTaskForm';
import Comment from './connectables/Comment';
import { fetchUsers, getUserNameById } from './modules/userService'
import RichTextEditor from './connectables/RichTextEditor';
import ProjectModal from './connectables/ProjectModal';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import TaskTimer from './modules/taskTimer';
import wsMessage from './modules/wsMessage';

function Workspace({ user, ws }) {
    const [tasks, setTasks] = useState([]);
    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState([]); // Храним список проектов
    const [isProjectOpen, setIsProjectOpen] = useState(false);
    const projectRef = useRef(null);
    const [selectedProjectId, setSelectedProjectId] = useState(null); // Храним ID выбранного проекта
    const [sortConfig, setSortConfig] = useState({ key: 'title', direction: 'ascending' });
    const [subtaskSortConfig, setSubtaskSortConfig] = useState({ key: 'title', direction: 'ascending' });
    const [subtasks, setSubtasks] = useState([]);
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [showTaskPanel, setShowTaskPanel] = useState(false);
    const [showSubtaskPanel, setShowSubtaskPanel] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingTask, setEditingTask] = useState(null);
    const [editingSubtask, setEditingSubtask] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [searchResult, setSearchResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [error, setError] = useState(null);
    const [parentTask, setParentTask] = useState(null);
    const [timers, setTimers] = useState({}); // Храним таймеры для всех задач
    const [pinnedComment, setPinnedComment] = useState(); // Храним закрепленный текст коммента
    const location = useLocation();
    const navigate = useNavigate();

    const activeTask = editingTask || editingSubtask;
    let currentUserId = user.id;
    const statusMapping = {
        new: "Новая задача",
        in_progress: "В работе",
        paused: "На паузе",
        deferred: "Отложена",
        closed: "Завершена",
    }
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [newTask, setNewTask] = useState({
        title: '',
        description: '',
        assigner_id: null,
        executor_id: null,
        observers: [],
        tag: '',
        parent_task_id: null,
        deadline: null,
        project_id: null,
        project_name: null,
    });

    const openTaskById = async (taskId) => {
        try {
            const response = await axios.get(`/api/tasks/${taskId}`);
            const taskData = response.data;
            setEditingTask(taskData);
            setIsModalOpen(true);
        } catch (error) {
            console.error('Ошибка при открытии задачи:', error);
        }
    };

    useEffect(() => {
        // Проверка URL для обработки ссылки на задачу
        const taskIdMatch = location.pathname.match(/\/tasks\/(\d+)/);
        if (taskIdMatch) {
            openTaskById(taskIdMatch[1]);
        }
    }, [location]);

    const fetchProjects = async () => {
        try {
            const response = await axios.get('/projects');
            const fetchedProjects = response.data;

            // сортировка проектов автоматически по времени идет, сделал по id
            const sortedProjects = fetchedProjects.sort((a, b) => a.id - b.id);

            setProjects(sortedProjects);

            if (sortedProjects.length > 0) {
                // Устанавливаем первый проект как предвыбранный
                setSelectedProjectId(sortedProjects[0].id);
            }
        } catch (error) {
            console.error('Ошибка при получении проектов:', error);
        }
    };

    useEffect(() => {
        fetchProjects(); // Загружаем проекты
        fetchUsers(setUsers); // Загружаем пользователей
    }, []);

    const getProjectById = (projectId, projects) => {
        return projects.find(project => project.id === projectId);
    };

    const fetchTasks = React.useCallback(async () => {
        try {
            const url = selectedProjectId
                ? `/projects/${selectedProjectId}/tasks` // Загружаем задачи по выбранному проекту
                : 'tasks';
            const response = await axios.get(url);

            setTasks(response.data);
            const extractSubtasks = (tasks) => {
                let subtasksList = [];
                tasks.forEach((task) => {
                    if (task.subtasks && task.subtasks.length > 0) {
                        subtasksList = subtasksList.concat(task.subtasks);
                        subtasksList = subtasksList.concat(extractSubtasks(task.subtasks)); // Рекурсивно извлекаем подзадачи
                    }
                });
                return subtasksList;
            };

            const allSubtasks = extractSubtasks(response.data);

            setSubtasks(allSubtasks); // Сохраняем подзадачи в состояние subtasks
        } catch (error) {
            console.error('Ошибка при получении задач:', error);
        }
    }, [selectedProjectId]);

    useEffect(() => {
        if (selectedProjectId !== null) {
            fetchTasks(); // Загружаем задачи только если проект выбран
            wsMessage(ws, fetchTasks);
        }
    }, [selectedProjectId, fetchTasks, ws, user.id]);

    const extractNumberFromTitle = (title) => {

        const eomPattern = /(\d{1,2}(?:\.\d{1,2})?[-.]\d{1,2}[-.]\d{5}-m\d\.\d+m?[-.]\d{2}[-.]\d{2})/g;
        const cokPattern = /(\d{1,2}(?:\.\d{1,2})?[-.]\d{1,2}[-.]\d{5})/g;

        const eomMatches = title.match(eomPattern);
        if (eomMatches) {
            return eomMatches[0];
        }

        const cokMatches = title.match(cokPattern);
        if (cokMatches) {
            return cokMatches[0];
        }

        console.log("Номер не найден ни по одному шаблону.");
        return null;
    };

    const searchCokByNumber = async (cokNumber) => {
        if (!cokNumber) return;

        setLoading(true);
        setIsSearch(true);
        setError(null);

        try {
            const response = await axios.get('/search-cok', { params: { cok: cokNumber } });

            if (response.data && response.data.cok) {
                const result = `${response.data.cok} — ${response.data.status || "Новый"}`;
                setSearchResult(result);
            } else {
                setSearchResult(null);
            }
        } catch (err) {
            setError('Ошибка при выполнении поиска ЦОК');
            setSearchResult(null);
        } finally {
            setLoading(false);
            setIsSearch(false);
        }
    };

    const searchEomByNumber = async (eomNumber, userId = 1) => {
        if (!eomNumber) return;

        setLoading(true);
        setIsSearch(true);
        setError(null);

        try {
            const response = await axios.get('/search-eom', { params: { eom: eomNumber, userId } });

            if (response.data && response.data.eom) {
                const result = `${response.data.eom} — ${response.data.status || "Новый"}`;
                setSearchResult(result);
            } else {
                setSearchResult(null);
            }
        } catch (err) {
            setError('Ошибка при выполнении поиска ЭОМ');
            setSearchResult(null);
        } finally {
            setLoading(false);
            setIsSearch(false);
        }
    };

    const openModal = async (task, isSubtask = false) => {
        setEditingTask(isSubtask ? null : task);
        setEditingSubtask(isSubtask ? task : null);
        setParentTask(null);
        setSearchResult(null);
        setLoading(true);
        setError(null);
        setShowSubtaskPanel(false);
        setIsDropdownOpen(false);

        const number = extractNumberFromTitle(task.title);
        const isMainTask = !isSubtask;

        try {
            // Получение данных таймера задачи
            const response = await axios.get(`/ui/tasks/${task.id}`);
            const taskData = response.data;

            // Установка таймера в состояние
            setTimers((prevTimers) => ({
                ...prevTimers,
                [task.id]: taskData.timer_seconds || 0, // Таймер из БД
            }));

            if (isMainTask) {
                if (taskData.subtasks && taskData.subtasks.length > 0) {
                    setEditingTask({ ...task, subtasks: taskData.subtasks });
                }

                if (number) {
                    searchCokByNumber(number); // Поиск ЦОК
                }
            } else {
                if (task.parent_task_id) {
                    try {
                        const parentResponse = await axios.get(`/ui/tasks/${task.parent_task_id}`);
                        setParentTask(parentResponse.data);
                    } catch (error) {
                        console.error('Ошибка при загрузке основной задачи:', error);
                    }
                }
                if (number) {
                    searchEomByNumber(number); // Поиск ЭОМ
                } else {
                    setSearchResult("номер не найден");
                    setLoading(false);
                }
            }

            setIsModalOpen(true);
        } catch (error) {
            console.error('Ошибка при загрузке данных задачи:', error);
            setError('Ошибка при загрузке данных задачи');
        } finally {
            setLoading(false);
        }
    };

    const closeModal = () => {
        setEditingTask(null);
        setEditingSubtask(null);
        setIsModalOpen(false);
        setIsEditing(false);
        setSearchResult(null);
        setLoading(false);
        setError(null);
        setIsDropdownOpen(false);
        setShowSubtaskPanel(false)
        setIsProjectOpen(false);

        if (location.pathname !== '/') {
            navigate('/tasks/');
        }
    };

    const handleTaskUpdate = async () => {
        try {
            const taskToUpdate = activeTask;
            const url = `/tasks/${taskToUpdate.id}`;
            await axios.put(url, taskToUpdate);
            fetchTasks();
            // wsMessage(ws, fetchTasks);
            setIsEditing(false);
        } catch (error) {
            console.error('Ошибка при обновлении задачи:', error);
        }
    };

    const addTask = async () => {
        try {
            await axios.post('/tasks', {
                ...newTask,
                observers: JSON.stringify(newTask.observers),
            });
            setNewTask({
                title: '',
                description: '',
                assigner_id: null,
                executor_id: null,
                observers: [],
                tag: '',
                parent_task_id: null,
                project_id: null,
            });
            fetchTasks();
            setShowTaskPanel(false);
        } catch (error) {
            console.error('Ошибка при добавлении задачи:', error);
        }
    };

    const toggleSubtasks = (task) => {
        if (selectedTaskId === task.id) {
            setSelectedTaskId(null);
            setSubtasks([]);
        } else {
            // Если задача не выбрана, загружаем подзадачи из `task.subtasks`
            if (task.subtasks && task.subtasks.length > 0) {
                setSubtasks(task.subtasks);
            } else {
                setSubtasks([]); // Если подзадач нет
            }
            setSelectedTaskId(task.id);
        }
    };

    const sortTasks = (key) => {
        // Определяем направление сортировки
        const direction = sortConfig.key === key && sortConfig.direction === 'ascending' ? 'descending' : 'ascending';

        // Обновляем состояние сортировки
        setSortConfig({ key, direction });

        // Выполняем сортировку сразу
        const sorted = [...tasks].sort((a, b) => {
            if (direction === 'ascending') {
                return a[key] > b[key] ? 1 : -1;
            } else {
                return a[key] < b[key] ? 1 : -1;
            }
        });

        // Обновляем отображаемые задачи
        setTasks(sorted);
    };

    const sortSubtasks = (key) => {
        let direction = 'ascending';
        if (subtaskSortConfig.key === key && subtaskSortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSubtaskSortConfig({ key, direction });

        if (activeTask?.subtasks) {
            const sortedSubtasks = [...activeTask.subtasks].sort((a, b) => {
                if (a[key] < b[key]) {
                    return direction === 'ascending' ? -1 : 1;
                }
                if (a[key] > b[key]) {
                    return direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
            setEditingTask({ ...editingTask, subtasks: sortedSubtasks });
        }
    };

    const sortedTasks = [...tasks].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    // получение подзадач для конкретной задачи, для перерисовки в таблице модалки
    const fetchSubtasks = async (parentTaskId) => {
        const response = await axios.get(`/tasks/${parentTaskId}`);
        const mainTask = response.data;
        setEditingTask(mainTask);
    }

    const handleParentTaskClick = async (parentTaskId) => {
        if (!parentTaskId) return;

        try {
            setEditingTask(null);
            setEditingSubtask(null);
            setParentTask(null);
            setSearchResult('Загрузка...');
            setLoading(true);
            setError(null);
            setShowSubtaskPanel(false);
            setIsDropdownOpen(false);

            const response = await axios.get(`/ui/tasks/${parentTaskId}`);
            const mainTask = response.data;
            setEditingTask(mainTask);
            setIsModalOpen(true);

            const number = extractNumberFromTitle(mainTask.title);
            if (number) {
                searchCokByNumber(number);
            } else {
                setSearchResult("номер не найден");
            }

            setLoading(false);
        } catch (error) {
            console.error('Ошибка при загрузке родительской задачи:', error);
            setLoading(false);
            setError('Ошибка при загрузке основной задачи');
        }
    };

    const notifications = new Set();

    const sendNotificationStatus = async (userId, task, status) => {
        try {
            await axios.post('/send-notification', {
                message: `Задача ${task?.title} ${status}`,
                userId: userId,
                senderId: currentUserId,
            });
        } catch (error) {
            console.error('Ошибка при отправке уведомления:', error);
            toast.error('Не удалось отправить уведомление');
        }
    }

    // Функция для обновления статуса задачи на сервере
    const updateTaskStatus = async (task, newStatus) => {
        try {
            const url = `/tasks/${task.id}/status`;
            await axios.put(url, { status: newStatus }); // Передаем только статус

            // Локальное обновление статуса задачи в состоянии
            setTasks((prevTasks) =>
                prevTasks.map((t) => (t.id === task.id ? { ...t, status: newStatus } : t))
            );

            // уведомление постановщику
            if (task?.assigner_id && task?.assigner_id !== currentUserId) {
                notifications.add(task.assigner_id);
            }

            // уведомление исполнителю
            if (task?.executor_id && task.executor_id !== currentUserId) {
                notifications.add(task.executor_id);
            }

            for (const userId of notifications) {
                await sendNotificationStatus(userId, task, statusMapping[newStatus].toLowerCase());
            }
        } catch (error) {
            console.error('Ошибка при обновлении статуса задачи:', error);
        }
    };


    // Функция для начала выполнения задачи
    const startTask = async (task) => {
        if (task) {
            try {
                task.status = 'in_progress';
                await updateTaskStatus(task, task.status); // Обновляем задачу на сервере
            } catch (error) {
                console.error('Ошибка при запуске задачи:', error);
            }
        }
    };

    // Функция для завершения задачи
    const completeTask = (task) => {
        if (task) {
            task.status = 'closed';
            updateTaskStatus(task, task.status);
        }
    };

    // Функция для приостановки выполнения задачи
    const pauseTask = (task) => {
        if (task) {
            task.status = 'paused';
            updateTaskStatus(task, task.status);
        }
    };

    // Функция для отложения задачи
    const deferTask = (task) => {
        if (task) {
            task.status = 'deferred';
            updateTaskStatus(task);
        }
    };

    // Функция для возврата задачи в работу
    const returnToInProgress = (task) => {
        if (task) {
            task.status = 'in_progress';
            updateTaskStatus(task, task.status);
        }
    };

    const deleteTask = async (taskId) => {
        try {
            if (taskId.parent_task_id) {
                await axios.delete(`/tasks/${taskId}`);
                setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
                fetchSubtasks();
            } else {
                await axios.delete(`/tasks/${taskId}`);
                setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
                fetchTasks();
            }

            setIsDropdownOpen(false);
            setIsModalOpen(false);

        } catch (error) {
            console.error('Ошибка при удалении задачи:', error);
        }
    };

    const toggleImportant = async (task) => {
        try {
            const updatedImportant = task.important ? 0 : 1;
            await axios.put(`/tasks/${task.id}/important`, { important: updatedImportant });

            setTasks((prevTasks) =>
                prevTasks.map((t) =>
                    t.id === task.id ? { ...t, important: updatedImportant } : t
                )
            );

            if (editingTask && editingTask.id === task.id) {
                setEditingTask({ ...editingTask, important: updatedImportant });
            }
            if (editingSubtask && editingSubtask.id === task.id) {
                setEditingSubtask({ ...editingSubtask, important: updatedImportant });
            }
        } catch (error) {
            console.error('Ошибка при обновлении важности задачи:', error);
        }
    };

    const formatTime = (seconds) => {
        const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
        const secs = String(seconds % 60).padStart(2, '0');
        return `${hours}:${minutes}:${secs}`;
    };

    const fetchTimer = async (taskId) => {
        try {
            const response = await axios.get(`/tasks/${taskId}/timer`);
            setTimers((prevTimers) => ({
                ...prevTimers,
                [taskId]: response.data.total_seconds,
            }));
        } catch (err) {
            console.error('Ошибка при получении таймера:', err);
        }
    };

    useEffect(() => {
        if (activeTask) {
            fetchTimer(activeTask.id);
        }
    }, [activeTask]);

    useEffect(() => {
        let intervalId;

        if (activeTask?.status === 'in_progress') {
            intervalId = setInterval(() => {
                setTimers((prevTimers) => ({
                    ...prevTimers,
                    [activeTask.id]: (prevTimers[activeTask.id] || 0) + 1,
                }));
            }, 1000);
        }

        return () => clearInterval(intervalId); // Очистка таймера при размонтировании
    }, [activeTask, activeTask?.status]);

    // форматируем время для предзаполненного инпута
    const prepareDatetime = (datetimeLocalValue) => {
        if (!datetimeLocalValue) return null; // Если значение пустое, возвращаем null

        const date = new Date(datetimeLocalValue);

        // Преобразуем в строку без изменения временной зоны
        const formattedDatetime = date
            .toLocaleString('sv-SE', { hour12: false })  // 'sv-SE' форматирует дату в формате 'YYYY-MM-DD HH:mm:ss'
            .replace(' ', 'T'); // Заменяем пробел на 'T' для соответствия формату базы данных

        return formattedDatetime;
    };

    const handleProjectClick = (projectId) => {
        console.log(`Клик по проекту с ID: ${projectId}`);
        setIsProjectOpen(!isProjectOpen);
        // Например, можно реализовать переход на страницу проекта
        // history.push(`/projects/${projectId}`);
    };

    return (
        <div className="workspace">
            <h2>Управление задачами</h2>
            <div className='add-task'>
                <button className="button" onClick={() => setShowTaskPanel(!showTaskPanel)}>
                    {showTaskPanel ? 'Скрыть панель задач' : 'Добавить задачу'}
                </button>
            </div>
            {showTaskPanel &&
                <CreateTaskForm
                    newTask={newTask}
                    setNewTask={setNewTask}
                    projects={projects}
                    users={users}
                    tasks={tasks}
                    addTask={addTask}
                    setShowPan={setShowTaskPanel}
                />
            }

            {/* Панель сортировки */}
            <div className='tasks-wrapper'>
                <table className='tasks-wrapper-table'>
                    <thead>
                        <tr className="task-header">
                            <th
                                colSpan="2"
                                className={`task-sort title-sort ${sortConfig.key === 'title' ? 'active-sort' : ''}`}
                                onClick={() => sortTasks('title')}
                            >
                                Название
                                {sortConfig.key === 'title' && (
                                    <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                                )}
                            </th>
                            <th
                                className={`task-sort updated-sort ${sortConfig.key === 'updated_at' ? 'active-sort' : ''}`}
                                onClick={() => sortTasks('updated_at')}
                            >
                                Активность
                                {sortConfig.key === 'updated_at' && (
                                    <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                                )}
                            </th>
                            <th
                                className={`task-sort assigner-sort ${sortConfig.key === 'assigner_id' ? 'active-sort' : ''}`}
                                onClick={() => sortTasks('assigner_id')}
                            >
                                Постановщик
                                {sortConfig.key === 'assigner_id' && (
                                    <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                                )}
                            </th>
                            <th
                                className={`task-sort executor-sort ${sortConfig.key === 'executor_id' ? 'active-sort' : ''}`}
                                onClick={() => sortTasks('executor_id')}
                            >
                                Исполнитель
                                {sortConfig.key === 'executor_id' && (
                                    <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                                )}
                            </th>
                            <th
                                className={`task-sort tag-sort ${sortConfig.key === 'tag' ? 'active-sort' : ''}`}
                                onClick={() => sortTasks('tag')}
                            >
                                Тэг
                                {sortConfig.key === 'tag' && (
                                    <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                                )}
                            </th>
                        </tr>
                    </thead>

                    <tbody className="task-list">
                        {projects.map((project) => (
                            <React.Fragment key={project.id}>
                                <tr className="project-row">
                                    <td
                                        colSpan="6"
                                        className="project-td"
                                        onClick={() => setSelectedProjectId(selectedProjectId === project.id ? null : project.id)}
                                    >
                                        <button
                                            className="show-tasks-button button-subtask"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Остановка всплытия события, чтобы обработчик на строке не срабатывал
                                                setSelectedProjectId(selectedProjectId === project.id ? null : project.id);
                                            }}
                                        >
                                            {selectedProjectId === project.id ? '-' : '+'}
                                        </button>

                                        {project.name}
                                    </td>
                                </tr>
                                {selectedProjectId === project.id && (
                                    sortedTasks
                                        .filter(task => task.project_id === project.id)
                                        .map((task) => (
                                            <React.Fragment key={task.id}>
                                                <tr className="task-wrapper">
                                                    <td className="icon-container">
                                                        {task.subtasks && task.subtasks.length > 0 && (
                                                            <button className='button-subtask' onClick={() => toggleSubtasks(task)}>
                                                                {selectedTaskId === task.id ? '-' : '+'}
                                                            </button>
                                                        )}
                                                    </td>
                                                    <td className="task-cell title" onClick={() => openModal(task)}>
                                                        {task.title}
                                                    </td>
                                                    <td className="task-cell updated-at">
                                                        {task.updated_at
                                                            ? `${new Date(task.updated_at).toLocaleDateString('ru-RU', { day: 'numeric', month: 'long' })}, ${new Date(task.updated_at).toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`
                                                            : 'Дата не указана'}
                                                    </td>
                                                    <td className="task-cell assigner">{getUserNameById(task.assigner_id, users)}</td>
                                                    <td className="task-cell executor">{getUserNameById(task.executor_id, users)}</td>
                                                    <td className="task-cell tag">{task.tag}</td>
                                                </tr>
                                                {selectedTaskId === task.id && (
                                                    subtasks.map((subtask) => (
                                                        (subtask.parent_task_id === task.id && (
                                                            <tr key={subtask.id} className="subtask-row">
                                                                <td></td>
                                                                <td className="task-cell title subtask" onClick={() => openModal(subtask, true)}>
                                                                    {subtask.title}
                                                                </td>
                                                                <td className="task-cell updated-at subtask">
                                                                    {subtask.updated_at
                                                                        ? `${new Date(subtask.updated_at).toLocaleDateString('ru-RU', { day: 'numeric', month: 'long' })}, ${new Date(subtask.updated_at).toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`
                                                                        : 'Дата не указана'}
                                                                </td>
                                                                <td className="task-cell assigner subtask">{getUserNameById(subtask.assigner_id, users)}</td>
                                                                <td className="task-cell executor subtask">{getUserNameById(subtask.executor_id, users)}</td>
                                                                <td className="task-cell tag subtask">{subtask.tag}</td>
                                                            </tr>
                                                        ))
                                                    ))
                                                )}
                                            </React.Fragment>
                                        ))
                                )}
                            </React.Fragment>
                        ))}

                    </tbody>

                </table>
            </div>

            {/* Модальное окно задачи */}
            {isModalOpen && (activeTask) && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-wrapper" onClick={(e) => e.stopPropagation()}>
                        <div className='pagetitle'>
                        <div className='pagetitle-menu'>
                            <p class="task-detail-subtitle-status ">Задача № {(activeTask)?.id || 'ID отсутствует'} - <span>{statusMapping[(activeTask)?.status]}</span></p> {/* Перенести в левый угол */}
                            <button className='menu-button' onClick={() => setShowSubtaskPanel(!showSubtaskPanel)}>
                                {showSubtaskPanel ? 'Скрыть панель задач' : 'Добавить подзадачу'}
                            </button>
                            </div>
                            <span className="close-button" onClick={closeModal}>
                                &times;
                            </span>
                        </div>
                        {showSubtaskPanel &&
                            <div className='modal-subtask'>
                                <CreateTaskForm
                                    newTask={newTask}
                                    projects={projects}
                                    setNewTask={setNewTask}
                                    users={users}
                                    tasks={tasks}
                                    addTask={addTask}
                                    activeTask={activeTask}
                                    setShowPan={setShowSubtaskPanel}
                                    user={user}
                                    fetchSubtasks={fetchSubtasks}
                                />
                            </div>
                        }
                        <div className='modal-wrapper__bottom'>
                            <div className='description'>
                                <div className='description-comment'>
                                <div className='task-detail-header'>
                                        <div className="task-detail-header-menu">
                                            <div className="title-edit-container">
                                                {isEditing ? (
                                                    <input
                                                        type="text"
                                                        value={activeTask?.title || ''}
                                                        onChange={(e) => {
                                                            const updatedValue = e.target.value;
                                                            if (editingTask) {
                                                                setEditingTask({ ...editingTask, title: updatedValue });
                                                            } else if (editingSubtask) {
                                                                setEditingSubtask({ ...editingSubtask, title: updatedValue });
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <h3 style={{ margin: '0' }}>
                                                        {(activeTask)?.title}
                                                    </h3>
                                                )}
                                            </div>
                                            <div
                                                id="task-detail-important-button"
                                                className="task-info-panel-important mutable"
                                                onClick={() => toggleImportant(activeTask)}
                                            >
                                                {activeTask?.important ? 'Это важная задача 🔥' : 'Сделать важной задачей'}
                                            </div>
                                        </div>
                                        <div className='copy-link-task'>
                                            <span
                                                className="copy-link-emoji"
                                                onClick={() => {
                                                    const link = `${window.location.origin}/tasks/${activeTask?.id}`;
                                                    navigator.clipboard.writeText(link).then(() => {
                                                        toast.success('Ссылка успешно скопирована!');
                                                    });
                                                }}
                                                title="Скопировать ссылку на задачу"
                                            >
                                                🔗
                                            </span>
                                        </div>
                                    </div>

                                    <div className='description-section'>
                                        {isEditing ? (
                                            <RichTextEditor
                                                newContent={(activeTask)?.description || ''}
                                                setNewContent={(content) => {
                                                    if (editingTask) {
                                                        setEditingTask({ ...editingTask, description: content });
                                                    } else if (editingSubtask) {
                                                        setEditingSubtask({ ...editingSubtask, description: content });
                                                    }
                                                }}
                                            />
                                        ) : (
                                            activeTask?.description ? (
                                                <p dangerouslySetInnerHTML={{ __html: activeTask.description }} />
                                            ) : (
                                                <p>Описание отсутствует</p>
                                            )
                                        )}
                                    </div>
                                    <div className='check-list-wrapper'>
                                        <p>+ добавить чек-лист</p>
                                    </div>
                                    <div className='task-detail-extra'>
                                        <div className='task-detail-group'>
                                            <p>Задача в проекте:</p>
                                            {!isEditing ? (
                                                <button
                                                    className='task-detail-btn'
                                                    ref={projectRef}
                                                    onClick={() => handleProjectClick(activeTask?.project_id)}
                                                    // style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                                >
                                                    {projects?.find(project => project.id === activeTask?.project_id)?.name || 'Проект не найден'}
                                                </button>
                                            ) : (
                                                <select
                                                    value={activeTask?.project_id || ''}
                                                    onChange={(e) => {
                                                        const selectedProject = projects?.find(project => project.id === parseInt(e.target.value));
                                                        if (editingTask) {
                                                            setEditingTask({
                                                                ...editingTask,
                                                                project_id: selectedProject ? selectedProject.id : null
                                                            });
                                                        } else if (editingSubtask) {
                                                            setEditingSubtask({
                                                                ...editingSubtask,
                                                                project_id: selectedProject ? selectedProject.id : null
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <option value="">Выберите проект</option>
                                                    {projects?.map((project) => (
                                                        <option key={project.id} value={project.id}>
                                                            {project.name || `Проект ${project.id}`}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                        {isProjectOpen &&
                                            <ProjectModal
                                                buttonRef={projectRef}
                                                project={getProjectById(activeTask?.project_id, projects)}
                                                setIsProjectOpen={setIsProjectOpen}
                                            />}
                                        {parentTask && editingSubtask && (
                                            <div className='task-detail-maintask'>
                                                <p>Основная задача:</p>
                                                <button
                                                    className='task-detail-btn'
                                                    onClick={() => handleParentTaskClick(parentTask.id)}
                                                    // style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                                >
                                                    {parentTask.title}
                                                    {console.log(parentTask)}
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    <div className='details-timer'>
                                        <TaskTimer activeTask={activeTask} timers={timers} />
                                    </div>

                                    <div className='task-view-buttonset'>
                                        {(activeTask)?.status === 'new' && (
                                            <button className="button" onClick={() => startTask(activeTask, (activeTask)?.status)}>Начать выполнение</button>
                                        )}
                                        {(activeTask)?.status === 'in_progress' && (
                                            <button className="button" onClick={() => pauseTask(activeTask, (activeTask)?.status)}>Пауза</button>
                                        )}
                                        {(activeTask)?.status === 'paused' && (
                                            <button className="button" onClick={() => startTask(activeTask, (activeTask)?.status)}>Продолжить</button>
                                        )}
                                        {(activeTask)?.status === 'deferred' && (
                                            <button className="button" onClick={() => returnToInProgress(activeTask, (activeTask)?.status)}>Вернуть в работу</button>
                                        )}
                                        {(activeTask)?.status !== 'closed' && (activeTask)?.status !== 'deferred' && (
                                            <button className="button" onClick={() => completeTask(activeTask, (activeTask)?.status)}>Завершить</button>
                                        )}
                                        <div className='dropdown-button'>
                                            <button className='dropdown-more' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>Еще</button>
                                            {isDropdownOpen && (
                                                <div className='dropdown-menu'>
                                                    {(activeTask)?.status === 'closed' && (
                                                        <button onClick={() => returnToInProgress(activeTask)}>Вернуть в работу</button>
                                                    )}
                                                    {(activeTask)?.status !== 'deferred' && (activeTask)?.status !== 'closed' && (
                                                        <button onClick={() => deferTask(activeTask)}>Отложить</button>
                                                    )}
                                                    {editingSubtask ? (
                                                        <button onClick={() => {
                                                            deleteTask(activeTask?.id);
                                                            handleParentTaskClick(parentTask.id);
                                                        }}
                                                        >Удалить</button>
                                                    ) : (
                                                        <button onClick={() => deleteTask(activeTask?.id)}>Удалить</button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        {!isEditing ? (
                                            <button className='btn-overlay-edit' onClick={() => setIsEditing(true)}>Редактировать</button>
                                        ) : (
                                            <>
                                                <button className='btn-overlay-save' onClick={handleTaskUpdate}>Сохранить изменения</button>
                                                <button className='btn-overlay-cancel' onClick={() => setIsEditing(false)}>Выйти из редактирования</button>
                                            </>
                                        )}
                                    </div>

                                </div>
                                {!isEditing && editingTask && editingTask.subtasks.length > 0 && (
                                    <div className='subtask-wrapper'>
                                        <div className='subtask-wrapper-header'>
                                            <div className='subtask-title-list'>
                                                <h3 style={{ margin: '0' }}>Подзадачи</h3>
                                            </div>
                                            <div className='tasks-wrapper'>
                                                <table className='tasks-wrapper-table'>
                                                    <thead className='task-header'>
                                                        <tr class="task-header">
                                                            <th
                                                                className={`task-sort ${subtaskSortConfig.key === 'title' ? 'acrive-sort' : ''}`}
                                                                onClick={() => sortSubtasks('title')}
                                                            >
                                                                Название
                                                                {subtaskSortConfig.key === 'title' && (
                                                                    <span className="arrow">{subtaskSortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                                                                )}
                                                            </th>
                                                            <th
                                                                className={`task-sort updated-sort ${subtaskSortConfig.key === 'updated_at' ? 'active-sort' : ''}`}
                                                                onClick={() => sortSubtasks('updated_at')}
                                                            >
                                                                Активность
                                                                {subtaskSortConfig.key === 'updated_at' && (
                                                                    <span className="arrow">{subtaskSortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                                                                )}
                                                            </th>
                                                            <th
                                                                className={`task-sort assigner-sort ${subtaskSortConfig.key === 'assigner_id' ? 'active-sort' : ''}`}
                                                                onClick={() => sortSubtasks('assigner_id')}
                                                            >
                                                                Постановщик
                                                                {subtaskSortConfig.key === 'assigner_id' && (
                                                                    <span className="arrow">{subtaskSortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                                                                )}
                                                            </th>
                                                            <th
                                                                className={`task-sort executor-sort ${subtaskSortConfig.key === 'executor_id' ? 'active-sort' : ''}`}
                                                                onClick={() => sortSubtasks('executor_id')}
                                                            >
                                                                Исполнитель
                                                                {subtaskSortConfig.key === 'executor_id' && (
                                                                    <span className="arrow">{subtaskSortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                                                                )}
                                                            </th>
                                                            <th
                                                                className={`task-sort tag-sort ${subtaskSortConfig.key === 'tag' ? 'active-sort' : ''}`}
                                                                onClick={() => sortSubtasks('tag')}
                                                            >
                                                                Тэг
                                                                {subtaskSortConfig.key === 'tag' && (
                                                                    <span className="arrow">{subtaskSortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                                                                )}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    {/* Список подзадач */}
                                                    <tbody className='subtasks'>
                                                        {editingTask && editingTask.subtasks ? (
                                                            editingTask.subtasks.map((subtask) => (
                                                                <tr key={subtask.id} className="subtask-row">
                                                                    <td className="task-cell title subtask" onClick={() => openModal(subtask, true)}>
                                                                        {subtask.title}
                                                                    </td>
                                                                    <td className="task-cell updated-at subtask">
                                                                        {subtask.updated_at ?
                                                                            `${new Date(subtask.updated_at).toLocaleDateString('ru-RU', { day: 'numeric', month: 'long' })}, ${new Date(subtask.updated_at).toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}` : 'Дата не указана'}
                                                                    </td>
                                                                    {/*<td className="task-cell status subtask">{subtask.status}</td>*/}
                                                                    <td className="task-cell assigner subtask">{getUserNameById(subtask.assigner_id, users)}</td>
                                                                    <td className="task-cell executor subtask">{getUserNameById(subtask.executor_id, users)}</td>
                                                                    <td className="task-cell tag subtask">{subtask.tag}</td>
                                                                </tr>
                                                            ))) : (
                                                            <p>Загрузка...</p>
                                                        )}
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!isEditing && pinnedComment && activeTask?.id === pinnedComment.task_id && (
                                    <motion.div
                                        className='result-section'
                                        initial={{ opacity: 0, y: -10 }} // Исходное состояние: прозрачность 0, немного выше
                                        animate={{ opacity: 1, y: 0 }}  // Анимация: прозрачность 1, положение по оси Y нормализовано
                                        exit={{ opacity: 0, y: -10 }}   // Анимация исчезновения
                                        transition={{ duration: 0.5 }}  // Плавная анимация за 0.3 секунды
                                    >
                                        <div className='result-wrapper'>
                                            <p style={{ margin: '0', paddingLeft: '10px', paddingBottom: '10px', textAlign: 'left', borderBottom: '1px solid #eaebedc7', }}>Результат</p>
                                            <div className='comment-result'>
                                                <div className='comment-header'>
                                                    <span className={
                                                        pinnedComment.user_id === currentUserId
                                                            ? 'user-name user-current'
                                                            : 'user-name user-other'
                                                    }> {pinnedComment.user_name} </span>
                                                    <span className='className="timestamp timestamp-left"'> {new Date(pinnedComment.timestamp).toLocaleString('ru-RU', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                    })} </span>
                                                </div>
                                                <p
                                                    style={{ margin: 0, marginBottom: '20px' }}
                                                    dangerouslySetInnerHTML={{ __html: pinnedComment.comment }}
                                                />
                                            </div>
                                        </div>
                                    </motion.div>
                                )}

                                {!isEditing && (
                                    <div className="comment-section">
                                        {activeTask ? (
                                            <Comment
                                                entity={activeTask}
                                                entityType="tasks"
                                                currentUser={currentUserId}
                                                users={users}
                                                setPinnedComment={setPinnedComment}
                                                projects={projects}
                                                ws={ws}
                                                user={user}
                                            />
                                        ) : (
                                            <p>Выберите задачу, чтобы просмотреть или добавить комментарии</p>
                                        )}
                                    </div>
                                )}

                            </div>

                            <div className='info-block'>
                                <div className='updated-task'>
                                    <p>{statusMapping[(activeTask)?.status]} <span className='time-to-status'>{new Date((activeTask)?.updated_at).toLocaleString('ru-RU', {
                                        day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'
                                    })}</span></p>
                                </div>
                                <div className='info-block__bottom'>

                                    <div className='status-block'>
                                        <div className='status-search'>
                                            <p>{activeTask.parent_task_id ? 'Статус ЭОМ:' : 'Статус ЦОК:'}</p>
                                            {loading ? (
                                                <p>Загрузка...</p>
                                            ) : error ? (
                                                <p>{error}</p>
                                            ) : isSearch ? (
                                                <p>Загрузка...</p>
                                            ) : searchResult ? (
                                                <p>{searchResult}</p>
                                            ) : (
                                                <p>{activeTask.parent_task_id ? 'ЭОМ не найден' : 'ЦОК не найден'}</p>
                                            )}
                                        </div>
                                    </div>

                                    <div className='data-card'>
                                        <div className='timing'>
                                            <div className='created_task'>
                                                <p> Создана: <span>{new Date((activeTask)?.created_at).toLocaleString('ru-RU', {
                                                    day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'
                                                })}</span></p>
                                            </div>
                                            <hr className='timing__hr' />
                                            <div className='deadline-task'>
                                                {!isEditing ? (
                                                    <p>Крайний срок: <span><b>{activeTask?.deadline == null ? 'Не указан' : new Date(activeTask?.deadline).toLocaleString('ru-RU', { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' })}</b></span></p>
                                                ) : (
                                                    <p>
                                                        Крайний срок:
                                                        <span>
                                                            <input
                                                                type='datetime-local'
                                                                value={prepareDatetime(activeTask?.deadline) || ''}
                                                                onChange={(e) => {
                                                                    const value = e.target.value || null;
                                                                    if (editingTask) {
                                                                        setEditingTask({ ...editingTask, deadline: value })
                                                                    } else if (editingSubtask) {
                                                                        setEditingSubtask({ ...editingTask, deadline: value })
                                                                    }
                                                                }}
                                                            />
                                                        </span>
                                                    </p>
                                                )}
                                            </div>
                                            <hr className='timing__hr' />
                                            <div className='details-timer-save'>
                                                <p> Затрачено: <span>{formatTime(timers[activeTask.id] || 0)}</span> </p>
                                            </div>
                                            <hr className='timing__hr' />
                                        </div>
                                        <div className='members'>
                                            <div style={!isEditing ? { textAlign: 'left' } : {}} className={!isEditing ? '' : 'assigner-block'}>
                                                <label>Постановщик</label>
                                                {!isEditing ? (
                                                    <p>{getUserNameById((activeTask)?.assigner_id, users)}</p>
                                                ) : (
                                                    <select
                                                        value={(activeTask)?.assigner_id || ''}
                                                        onChange={(e) => {
                                                            const updatedValue = parseInt(e.target.value);
                                                            if (editingTask) {
                                                                setEditingTask({ ...editingTask, assigner_id: updatedValue });
                                                            } else if (editingSubtask) {
                                                                setEditingSubtask({ ...editingSubtask, assigner_id: updatedValue });
                                                            }
                                                        }}
                                                        disabled={!isEditing}
                                                    >
                                                        <option value="">Выберите постановщика</option>
                                                        {users.map((user) => (
                                                            <option key={user.id} value={user.id}>{user.name}</option>
                                                        ))}
                                                        disabled={!isEditing}
                                                    </select>
                                                )}
                                            </div>

                                            <div style={!isEditing ? { textAlign: 'left' } : {}} className={!isEditing ? '' : 'executor-block'}>
                                                <label>Исполнитель</label>
                                                {!isEditing ? (
                                                    <p>{getUserNameById((activeTask)?.executor_id, users)}</p>
                                                ) : (
                                                    <select
                                                        value={(activeTask)?.executor_id || ''}
                                                        onChange={(e) => {
                                                            const updatedValue = parseInt(e.target.value);
                                                            if (editingTask) {
                                                                setEditingTask({ ...editingTask, executor_id: updatedValue });
                                                            } else if (editingSubtask) {
                                                                setEditingSubtask({ ...editingSubtask, executor_id: updatedValue });
                                                            }
                                                        }}
                                                        disabled={!isEditing}
                                                    >
                                                        <option value="">Выберите исполнителя</option>
                                                        {users.map((user) => (
                                                            <option key={user.id} value={user.id}>{user.name}</option>
                                                        ))}
                                                        disabled={!isEditing}
                                                    </select>
                                                )}
                                            </div>

                                            <div style={!isEditing ? { textAlign: 'left' } : {}} className={!isEditing ? '' : 'observers-block'}>
                                                <label> Наблюдатели</label>
                                                {!isEditing ? (
                                                    <>
                                                        {Array.isArray(activeTask?.observers) && activeTask?.observers.length > 0 ? (
                                                            activeTask.observers
                                                                .map((id) => {
                                                                    const user = users.find((user) => user.id === id);
                                                                    return user ? <p key={id}>{user.name}</p> : null;
                                                                })
                                                        ) : (
                                                            <p>Наблюдатели не назначены</p>
                                                        )}
                                                    </>
                                                ) : (
                                                    <select
                                                        multiple
                                                        value={Array.isArray((activeTask)?.observers)
                                                            ? (activeTask)?.observers
                                                            : []}
                                                        onChange={(e) => {
                                                            const selectedOptions = Array.from(e.target.selectedOptions, option => parseInt(option.value));
                                                            if (editingTask) {
                                                                setEditingTask({ ...editingTask, observers: selectedOptions });
                                                            } else if (editingSubtask) {
                                                                setEditingSubtask({ ...editingSubtask, observers: selectedOptions });
                                                            }
                                                        }}
                                                    >
                                                        {users.map((user) => (
                                                            <option key={user.id} value={user.id}>{user.name}</option>
                                                        ))}
                                                    </select>

                                                )}
                                            </div>

                                            <div style={!isEditing ? { textAlign: 'left' } : {}} className={!isEditing ? '' : 'tags-block'}>
                                                <label>Теги</label>
                                                {!isEditing ? (
                                                    <p>{(activeTask)?.tag || 'Тег не указан'}</p>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        value={(activeTask)?.tag || ''}
                                                        onChange={(e) => {
                                                            const updatedValue = e.target.value;
                                                            if (editingTask) {
                                                                setEditingTask({ ...editingTask, tag: updatedValue });
                                                            } else if (editingSubtask) {
                                                                setEditingSubtask({ ...editingSubtask, tag: updatedValue });
                                                            }
                                                        }}
                                                        disabled={!isEditing}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    );
}

export default Workspace;
