import '../styles/Workspace.css'
import RichTextEditor from './RichTextEditor';
import React, { useState, useEffect } from 'react';

function CreateTaskForm({
    user,
    newTask,
    setNewTask,
    users,
    tasks,
    projects,
    addTask,
    activeTask,
    setShowPan,
    fetchSubtasks,
}) {
    const [projectName, setProjectName] = useState('');

    useEffect(() => {
        if (activeTask?.project_id && Array.isArray(projects) && projects.length > 0) {
            const project = projects.find(p => p.id === activeTask.project_id);
            setProjectName(project ? project.name : `Проект ${activeTask.project_id}`);
        } else {
            setProjectName(`Проект ${activeTask?.project_id || ''}`);
        }
    }, [activeTask, projects]);

    if (activeTask && user) {
        newTask.assigner_id = user.id;
        newTask.parent_task_id = activeTask.id;
        newTask.project_id = activeTask.project_id || '';
    }

    return (
        <div className="task-panel">
            <label className='task-panel__label task-panel__label-title'>
                {activeTask ? 'Название подзадачи' : 'Название задачи'}
                <input
                    type="text"
                    value={newTask.title}
                    onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
                />
            </label>
            <label className='task-panel__label task-panel__label-desc'>
                {activeTask ? "Описание подзадачи" : "Описание задачи"}
                <RichTextEditor
                    newContent={newTask.description}
                    setNewContent={(content) => setNewTask({ ...newTask, description: content })}
                />
            </label>

            {activeTask ? (
                <label className='task-panel__label task-panel__label-assigner'>
                    Постановщик:
                    <select disabled
                        value={newTask.assigner_id}
                        onChange={(e) => setNewTask({ ...newTask, assigner_id: parseInt(e.target.value) })}
                    >
                        {user ? (
                            <option value="">{user.name}</option>
                        ) : (
                            <option value="">Загрузка челика</option>
                        )}
                    </select>
                </label>
            ) : (
                <label className='task-panel__label task-panel__label-assigner'>
                    Постановщик:
                    <select 
                        value={newTask.assigner_id || ''}
                        onChange={(e) => setNewTask({ ...newTask, assigner_id: parseInt(e.target.value) })}
                    >
                        <option value="">Выберите постановщика</option>
                        {users.map((user) => (
                            <option key={user.id} value={user.id}>{user.name}</option>
                        ))}
                    </select>
                </label>
            )}

            <label className='task-panel__label task-panel__label-executor'>
                Исполнитель:
                <select
                    value={newTask.executor_id}
                    onChange={(e) => setNewTask({ ...newTask, executor_id: parseInt(e.target.value) })}
                >
                    <option value="">Выберите исполнителя</option>
                    {users.map((user) => (
                        <option key={user.id} value={user.id}>{user.name}</option>
                    ))}
                </select>
            </label>

            <label className='task-panel__label task-panel__label-executor'>
                Наблюдатели:
                <select
                    multiple
                    value={newTask.observers}
                    onChange={(e) =>
                        setNewTask({
                            ...newTask,
                            observers: Array.from(e.target.selectedOptions, (option) => parseInt(option.value)),
                        })
                    }
                >
                    {users.map((user) => (
                        <option key={user.id} value={user.id}>{user.name}</option>
                    ))}
                </select>
            </label>

            {activeTask ? (
                <label className='task-panel__label task-panel__label-project'>
                    Проект:
                    <select disabled>
                        <option value={activeTask?.project_id || ''}>
                            {projectName || `Проект ${activeTask?.project_id || ''}`}
                        </option>
                    </select>
                </label>
            ) : (
                <label className='task-panel__label task-panel__label-project'>
                    Проект:
                    <select
                        value={newTask.project_id || ''}
                        onChange={(e) => {
                            const selectedProject = projects?.find(project => project.id === parseInt(e.target.value));
                            setNewTask({
                                ...newTask,
                                project_id: selectedProject ? selectedProject.id : null
                            });
                        }}
                    >
                        <option value="">Выберите проект</option>
                        {projects?.map((project) => (
                            <option key={project.id} value={project.id}>
                                {project.name || `Проект ${project.id}`}
                            </option>
                        ))}
                    </select>
                </label>
            )}


            {activeTask ? (
                <label className='task-panel__label task-panel__label-parent'>
                    Основная или дополнительная задача
                    <select disabled
                        value={newTask.parent_task_id}
                        onChange={(e) => setNewTask({ ...newTask, parent_task_id: parseInt(e.target.value) })}
                    >
                        <option key={activeTask.id} value={activeTask.id}>{activeTask.title}</option>
                    </select>
                </label>
            ) : (
                <label className='task-panel__label task-panel__label-parent'>
                    Основная или дополнительная задача
                    <select
                        value={newTask.parent_task_id || ''}
                        onChange={(e) => setNewTask({ ...newTask, parent_task_id: parseInt(e.target.value) })}
                    >
                        <option value="">Основная задача</option>
                        {tasks.map((task) => (
                            <option key={task.id} value={task.id}>{task.title}</option>
                        ))}
                    </select>
                </label>
            )}
            <label className='task-panel__label task-panel__label-tags'>
                Теги
                <input
                    type="text"
                    placeholder="Тэг"
                    value={newTask.tag}
                    onChange={(e) => setNewTask({ ...newTask, tag: e.target.value })}
                />
            </label>
            <label className='task-panel__label task-panel__label-deadline'>
                Дедлайн
                <input
                    type='datetime-local'
                    value={newTask.deadline || ''}
                    onChange={(e) => {
                        const value = e.target.value || null;
                        setNewTask({ ...newTask, deadline: value })
                    }}
                />
            </label>

            {activeTask ? (
                <button className= 'button'  onClick={() => { addTask(); fetchSubtasks(activeTask.id); setShowPan(false) }}>Сохранить задачу</button>
            ) : (
                <button className= 'button'  onClick={() => { addTask(); setShowPan(false) }}>Сохранить задачу</button>
            )}
        </div >
    )
}

export default CreateTaskForm;
