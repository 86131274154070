import React, { useState, useEffect, useCallback } from "react";
import axios from './modules/axiosInstance';
import "./styles/Notifications.css";
import { toast } from "react-toastify";
import wsMessage from "./modules/wsMessage";

function Notifications({ user, ws }) {
  const [notifications, setNotifications] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const handleModalToggle = useCallback(() => {

    setIsModalOpen(prev => !prev);

    const unseenIds = notifications
      .filter(notif => !notif.modal_shown)
      .map(notif => notif.id);

    if (unseenIds.length > 0) {
      axios
        .post(`/notifications/mark-modal-seen`, { notificationIds: unseenIds, userId: user.id })
        .then(() => {
          setUnreadCount(0);
        })
        .catch((err) => {
          console.error("Ошибка при отметке уведомлений как просмотренных в модальном окне:", err);
        });
    } else {
    }
  }, [notifications, user.id]);

  const fetchNotifications = useCallback(async () => {
    try {
      //  Вот тут начинается бесконечный вызов fetchNotifications, если у этого хука в зависимости указать handleModalToggle (пока из зависимостей его уберу)
      //  Это сто процентов из-за зависимостей у handleModalToggle

      const response = await axios.get(`/notifications?userId=${user.id}`);
      const allNotifications = response.data;

      const unshownNotifications = allNotifications.filter((notif) => !notif.toast_shown);
      const unseenInModal = allNotifications.filter((notif) => !notif.modal_shown);

      // Показ toast уведомлений
      unshownNotifications.forEach((notif) => {
        toast.info(
          <div dangerouslySetInnerHTML={{ __html: notif.message }} />,
          {
            onClick: () => {
              toast.dismiss();
              setIsModalOpen(true);
            },
            style: { cursor: 'pointer' },
          }
        );

        // Отметить toast как показанное
        axios.post(`/notifications/mark-toast-shown`, {
          notificationId: notif.id,
          userId: user.id,
        });
      });

      setNotifications(allNotifications);
      setUnreadCount(unseenInModal.length);
    } catch (error) {
      console.error("Ошибка при получении уведомлений:", error);
    }
  }, [user.id]);
  useEffect(() => {
    fetchNotifications();
    wsMessage(ws, fetchNotifications)
  }, [user.id, ws, fetchNotifications]);

  const handleClickOutside = useCallback(e => {
    const modal = document.querySelector('.modal');
    const header = document.querySelector('.notifications-header');

    if (header && header.contains(e.target)) {
      return;
    }

    if (modal && !modal.contains(e.target)) {
      handleModalToggle();
    }
  }, [handleModalToggle]);

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen, handleClickOutside]);

  return (
    <div>
      <div className="notifications-header" onClick={handleModalToggle}>
        <svg
          className="notify-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 100 100"
          style={{ cursor: "pointer" }}
        >
          <defs>
            <style>
              {`
        .cls-1 {
          fill: #fa9632;
          opacity: 0;
          transition: opacity 0.4s ease;
        }
        .cls-2 {
          fill: #fc9533;
          transition: fill 0.4s ease;
        }
        svg:hover .cls-1 {
          opacity: 0.2;
        }
      `}
            </style>
          </defs>
          <circle className="cls-1" cx="50" cy="50" r="50" />
          <g className="bell">
            <path
              className="cls-2"
              d="M71.64,65.6c0,1.69-1.37,3.06-3.06,3.06H31.42c-1.69,0-3.06-1.37-3.06-3.06,0-.58.16-1.14.46-1.61.29-.48.72-.87,1.24-1.13,1.1-.55,2.01-1.39,2.63-2.4.63-1.01.97-2.19.97-3.43v-11.98c0-7.7,5.32-14.15,12.49-15.89-.02-.15-.03-.3-.03-.46,0-2.14,1.74-3.88,3.88-3.88s3.88,1.74,3.88,3.88c0,.15,0,.31-.03.46,2.97.72,5.62,2.24,7.7,4.33,2.96,2.96,4.79,7.04,4.79,11.56v11.98c0,2.47,1.4,4.72,3.61,5.82.52.26.94.65,1.24,1.13.29.47.46,1.03.46,1.61Z"
            />
            <path
              className="cls-2"
              d="M55.34,70.15c0,2.95-2.39,5.34-5.34,5.34s-5.34-2.39-5.34-5.34h10.69Z"
            />
          </g>
        </svg>
        {unreadCount > 0 && (
          <span className="notification-count">{unreadCount}</span>
        )}
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-bar">
              {notifications.length === 0 ? (
                <p>Уведомлений нет</p>
              ) : (
                <p>Уведомления</p>
              )}
              <span className="close-button" onClick={handleModalToggle}>
                &times;
              </span>
            </div>
            {notifications.length !== 0 && (
              <ul className="notification-list">
                {notifications
                  .slice()
                  .sort((a, b) => b.id - a.id)
                  .map((notif) => (
                    <li key={notif.id}
                      className={`notification-card ${notif.modal_shown ? '' : 'unread-card'}`}
                    >
                      <div
                        className="notification-message"
                        dangerouslySetInnerHTML={{ __html: notif.message }}
                      />
                      <div className="notification-timestamp">
                        {new Date(notif.timestamp).toLocaleString()}
                      </div>
                    </li>
                  ))}
              </ul>
            )}

          </div>
        </div>
      )}
    </div>
  );
}

export default Notifications;
