import React, { useState } from 'react';
import { Tabs, Tab, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Route, Routes, Link } from 'react-router-dom';

import Login from './Login';
import SearchPage from './SearchPage';
import TopPage from './TopPage';
import WeeklyStats from './WeeklyStats';
import AccountManagement from './AccountManagement';
import NotificationManagement from './NotificationManagement';
import Notifications from './Notifications';
import NewsPage from './NewsPage';
import Workspace from './Workspace';

import logos from './assets/logos.svg';
import { ToastContainer } from 'react-toastify';
import { useUserApp } from './hooks/useUserApp';
import './styles/App.css';
import './styles/tabpanel.css';
import './styles/style.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { user, errorMessage, login, logout, wsRef, activeTab, handleTabChange } = useUserApp();
  const [userInfo, setUserInfo] = useState(false);

  return (
    <div className="App">
      <ToastContainer />
      <div className='header'>
        {user && (
          <div className="notifications-logout">
            <Notifications user={user} ws={wsRef.current} />
            <span className="user-info">Пользователь:
              <button
                className='user-info__btn'
                onClick={() => setUserInfo(!userInfo)}>
                {user.name}
              </button>
            </span>
            <button className="button" onClick={logout}>Выйти</button>
          </div>
        )}
      </div>
      {userInfo && (
        <div className='user-info__modal'>
          <p>{user.name}</p>
          <p>{user.username}</p>
          <p>{user.user_group}</p>
        </div>
      )}
      <div className='logo-container'>
        <img src={logos} alt="Logo" className='logo' />
      </div>
      <div className="menu">
        {!user ? (
          <Login onLogin={login} errorMessage={errorMessage} />
        ) : (
          <>
            <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
              <TabList>
                <Tab><Link to="/news-page">Новости</Link></Tab>
                <Tab><Link to="/search">Поиск ЦОК</Link></Tab>
                <Tab><Link to="/top">Топ ЦОКи</Link></Tab>
                <Tab><Link to="/stats">Статистика</Link></Tab>
                <Tab><Link to="/tasks">Задачи</Link></Tab>
                {user.role === 'manager' && <Tab><Link to="/account-management">Управление аккаунтами</Link></Tab>}
                {user.role === 'manager' && <Tab><Link to="/notification-management">Управление уведомлениями</Link></Tab>}
              </TabList>
            </Tabs>
            <Routes>
              <Route path="/news-page" element={<NewsPage user={user} ws={wsRef.current} />} />
              <Route path="/search" element={<SearchPage user={user} />} />
              <Route path="/top" element={<TopPage />} />
              <Route path="/stats" element={<WeeklyStats user={user} />} />
              <Route path="/tasks" element={<Workspace user={user} ws={wsRef.current}/>} />
              <Route path="/tasks/:id" element={<Workspace user={user} ws={wsRef.current}/>} />
              <Route path="//account-management" element={<AccountManagement user={user} />} />
              <Route path="/notification-management" element={<NotificationManagement user={user} />} />
            </Routes>
          </>
        )}
      </div>
    </div>
  );
}

export default App;