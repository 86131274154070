import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from '../modules/axiosInstance';
import RichTextEditor from './RichTextEditor';
import wsMessage from '../modules/wsMessage';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { getUserNameById } from '../modules/userService';
import '../styles/Comment.css';

const Comment = ({
    entity,
    entityType,
    currentUser,
    users,
    setPinnedComment,
    projects,
    ws,
    user
}) => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [replyTo, setReplyTo] = useState(null);
    const [isRichEditorActive, setIsRichEditorActive] = useState(false);
    const [isPinned, setIsPinned] = useState(false);
    const [activeCommentId, setActiveCommentId] = useState(null);
    const [editingComment, setEditingComment] = useState(null);
    const editorRef = useRef(false);
    const navigate = useNavigate();

    const fetchComments = useCallback(async () => {
        if (!entity?.id) {
            console.log('Таск не найден или задача удалена, приостановка получения задач');
            return;
        }

        try {
            const response = await axios.get(`/${entityType}/${entity.id}/comments`);

            setComments(response.data);

            const pinnedComment = response.data.find(comment => comment.pinned === 1);
            if (pinnedComment) {
                setPinnedComment(pinnedComment);
            }
        } catch (error) {
            console.error('Ошибка при загрузке комментариев:', error);
        }
    }, [entity.id, setPinnedComment, entityType]);

    useEffect(() => {
        fetchComments();
        wsMessage(ws, fetchComments);
    }, [entity.id, entityType, fetchComments, ws, user.id]);

    // Добавление комментария
    const addComment = async () => {
        setIsRichEditorActive(false);
        const trimmedComment = newComment.trim();
        if (!trimmedComment) return;

        try {
            if (editingComment) {
                if (entityType === 'news') {
                    await axios.put(`/${entityType}/comments/${editingComment}`, {
                        comment: trimmedComment,
                        edited: 1,
                    });
                } else if (entityType === 'tasks') {
                    await axios.put(`/${entityType}/comments/${editingComment}`, {
                        comment: trimmedComment,
                        edited: 1,
                    });
                }
                // Обновляем комментарий в списке
                setComments(prevComments =>
                    prevComments.map(c =>
                        c.id === editingComment ? { ...c, comment: trimmedComment, edited: 1 } : c
                    )
                );
                setNewComment('');
                setEditingComment(null);
            } else {
                const response = await axios.post(`/${entityType}/${entity.id}/comments`, {
                    userId: currentUser,
                    comment: trimmedComment,
                    parentCommentId: replyTo || null,
                    pinned: isPinned,
                });

                const newCommentWithUserName = {
                    ...response.data,
                    userId: currentUser,
                    user_name: getUserNameById(currentUser, users),
                };

                setComments(prevComments => [...prevComments, newCommentWithUserName]);
                fetchComments();
                setNewComment('');

                const notifications = new Set();
                if (replyTo) {
                    const parentComment = comments.find(c => c.id === replyTo);
                    if (parentComment && parentComment.user_id !== currentUser) {
                        notifications.add(parentComment.user_id);
                    }
                }
                if (entityType === 'tasks') {

                    // уведомление постановщику
                    if (entity?.assigner_id && entity?.assigner_id !== currentUser) {
                        notifications.add(entity.assigner_id);
                    }

                    // уведомление исполнителю
                    if (entity?.executor_id && entity.executor_id !== currentUser) {
                        notifications.add(entity.executor_id);
                    }

                    // уведомление наблюдателю, если есть коммент от него
                    if (entity?.observers) {
                        const observersWithComments = comments
                            .filter(comment => entity.observers.includes(comment.user_id)) // Наблюдатели с комментариями
                            .map(comment => comment.user_id); // Получаем их user_id

                        observersWithComments.forEach(observerId => {
                            if (observerId !== currentUser) {
                                notifications.add(observerId);
                            }
                        });
                    }

                    if (isPinned) {
                        setPinnedComment(newCommentWithUserName);
                        setIsPinned(false);
                    }
                }

                // Отправка всех уведомлений
                for (const userId of notifications) {
                    await sendNotification(userId, entity, newCommentWithUserName.comment);
                }
                setReplyTo(null);
            }
        } catch (error) {
            console.error('Ошибка при добавлении комментария:', error);
        }
    };

    const handleEditingComment = (comment) => {
        setEditingComment(comment.id);
        setNewComment(comment.comment);
        setIsRichEditorActive(true);
    }

    const sendNotification = async (userId, entity, commText) => {
        try {
            if (entityType === 'tasks') {
                // Создаем ссылку на задачу
                const projectName = projects.find(project => project.id === entity.project_id)?.name || 'неизвестный проект';

                const taskLink = `<a href="${window.location.origin}/${entityType}/${entity.id}" target="_blank" rel="noopener noreferrer" style="color: blue;">${entity.title} (в проекте ${projectName})</a>`;

                // Отправка уведомления
                await axios.post('/send-notification', {
                    message: `<span class="username">${getUserNameById(currentUser, users)}</span><br /><br />Добавлен комментарий к задаче ${taskLink} со следующим текстом: ${commText}`,
                    userId: userId,
                    senderId: currentUser,

                });
                // Если нужно переключиться на вкладку с задачей/новостью
                navigate(`/${entityType}/${entity.id}`);  // Переключаемся на задачу/новость в текущей вкладке

            } else if (entityType === 'news') {

                // ссылка на новость
                const newsLink = `<a href="${window.location.origin}/news-page/" target="_self" rel="noopener noreferrer" style="color: blue;">${entity.title}</a>`;


                await axios.post('/send-notification', {
                    message: `<span class="username">${getUserNameById(currentUser, users)}</span><br /><br />Добавлен комментарий к новости ${newsLink} со следующим текстом: ${commText}`,
                    userId: userId,
                    senderId: currentUser,
                });
                // Если нужно переключиться на вкладку с задачей/новостью
                navigate(`/news-page/`);  // Переключаемся на задачу/новость в текущей вкладке
            }


        } catch (error) {
            console.error('Ошибка при отправке уведомления:', error);
            toast.error('Не удалось отправить уведомление');
        }
    }

    const handleReply = (comment) => {
        setReplyTo(comment.id);
        setIsRichEditorActive(true);

        const userName = getUserNameById(comment.user_id, users);
        const newText = `${userName},\u00A0`;
        setNewComment(newText);

        setTimeout(() => {
            if (editorRef.current) {
                const quill = editorRef.current.getEditor();
                const length = quill.getLength();
                const newCommentSection = document.querySelector(`.new-comment-section[data-comment-id = "${entity.id}"]`);
                if (newCommentSection) {
                    newCommentSection.scrollIntoView({
                        behavior: "smooth",
                        block: 'end'
                    });
                }
                setTimeout(() => {
                    quill.setSelection(length, 0);
                }, 500)
            }
        }, 0);
    };

    // Удаление комментария
    const deleteComment = async (commentId) => {
        try {
            await axios.delete(`/${entityType}/comments/${commentId}`);
            const commentToDelete = comments.find(comment => comment.id === commentId);

            setComments(comments.filter((c) => c.id !== commentId));
            if (commentToDelete?.pinned === 1) setPinnedComment(null);
        } catch (error) {
            console.error('Ошибка при удалении комментария:', error);
        }
    };

    const handleClickOutside = useCallback(e => {
        const divs = document.querySelectorAll('.more-actions');
        const buttons = document.querySelectorAll('.more-btn');

        const clickedButton = Array.from(buttons).find(button => button.contains(e.target));
        const clickedOutside = !Array.from(divs).some(div => div.contains(e.target));

        if (clickedButton) {
            console.log('click on button', activeCommentId)
            return;
        }

        if (clickedOutside) {
            console.log('click outside btn', activeCommentId)
            setActiveCommentId(null);
            console.log('click outside btn NULL', activeCommentId)
        }
    }, [activeCommentId]);

    useEffect(() => {
        if (activeCommentId) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [activeCommentId, handleClickOutside]);

    const handleFocus = () => {
        setIsRichEditorActive(true);
        setTimeout(() => {
            if (editorRef.current) {
                editorRef.current?.focus();
                if (entityType === 'tasks') {
                    const newCommentSection = document.querySelector(`.new-comment-section[data-comment-id = "${entity.id}"]`);
                    if (newCommentSection) {
                        newCommentSection.scrollIntoView({
                            behavior: "smooth",
                            block: 'end'
                        });
                    }
                }
            }
        }, 0);
    };

    return (
        <div className="task-comments comments-section">
            <h3 style={{ margin: '0', marginBottom: '20px' }}>Комментарии</h3>
            {comments.map((comment) => (
                <motion.div
                    className={comment.parent_comment_id ? 'comment-wrapper' : 'comment'}
                    key={comment.id}
                    initial={{ opacity: 0 }}   // Начальная прозрачность
                    animate={{ opacity: 1 }}   // Конечная прозрачность
                    exit={{ opacity: 0 }}      // Прозрачность при удалении
                    transition={{ duration: 0.5 }}  // Длительность анимации
                >
                    <div className='comment-main'>
                        <div className="comment-header">
                            <span
                                className={
                                    comment.user_id === currentUser
                                        ? 'user-name user-current'
                                        : 'user-name user-other'
                                }
                            >
                                {comment.user_name}
                            </span>
                            <span className="timestamp timestamp-left">
                                {new Date(comment.timestamp).toLocaleString('ru-RU', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                            </span>
                            {comment.edited && (
                                <span className='timestamp'>изменено</span>
                            )}
                        </div>
                        <p
                            className='comment-main__text'
                            dangerouslySetInnerHTML={{ __html: comment.comment }}
                        />
                    </div>
                    <div className='action-buttons'>
                        <button
                            className='news-comment__button'
                            onClick={() => console.log("Лайк")}
                        >
                            Нравится
                        </button>
                        <button className="reply-button" onClick={() => {
                            handleReply(comment);
                        }}>
                            Ответить
                        </button>
                        {comment.user_id === currentUser && (
                            <>
                                <button
                                    className='more-btn'
                                    onClick={() => setActiveCommentId(activeCommentId === comment.id ? null : comment.id)}
                                >
                                    Еще
                                </button>
                                {activeCommentId === comment.id && (
                                    <div className='more-actions'>
                                        <button
                                            className='news-comment__button'
                                            onClick={() => handleEditingComment(comment)}

                                        >Редактировать</button>
                                        <button
                                            className="delete-button"
                                            onClick={() => deleteComment(comment.id)}
                                        >
                                            Удалить
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </motion.div>
            ))}
            <div className="new-comment-section" data-comment-id={entity.id}>
                <div
                    className={`input-container ${isRichEditorActive ? 'expanded' : ''}`}
                >
                    {isRichEditorActive ? (
                        <RichTextEditor
                            newContent={newComment}
                            setNewContent={setNewComment}
                            ref={editorRef}
                            scrollToEditor={isRichEditorActive}
                            entityType={entityType}
                        />
                    ) : (
                        <input
                            type="text"
                            className="comment-input"
                            placeholder='Добавить комментарий'
                            onFocus={handleFocus}
                        />
                    )}
                </div>

                {isRichEditorActive && (
                    <div className="comment-buttons">
                        <button className="comment-button" onClick={addComment}>
                            Отправить
                        </button>
                        <button
                            className="comment-button"
                            onClick={() => {
                                setIsRichEditorActive(false);
                                setReplyTo(null);
                                setEditingComment(null);
                                setNewComment('');
                            }}
                        >
                            Отмена
                        </button>
                        {entityType === 'tasks' && (
                            <label className='pinned-label'>
                                <input
                                    className='pinned-input'
                                    type='checkbox'
                                    checked={isPinned}
                                    onChange={e => { setIsPinned(e.target.checked) }}
                                />
                                Зафиксировать как результат работы
                            </label>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Comment;
