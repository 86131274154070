import { useEffect, useRef } from 'react';
import '../styles/Project.css';

function ProjectModal({ project, setIsProjectOpen, buttonRef }) {
    const modalRef = useRef(null);

    useEffect(() => {
        function closeProject(e) {
            // Проверяем, что клик не произошел на кнопке открытия и не внутри модального окна
            if (
                modalRef.current && 
                !modalRef.current.contains(e.target) && 
                !(buttonRef.current && buttonRef.current.contains(e.target))
            ) {
                setIsProjectOpen(false);
            }
        }

        document.addEventListener('mousedown', closeProject);

        return () => {
            document.removeEventListener('mousedown', closeProject);
        };
    }, [setIsProjectOpen, buttonRef]);

    return (
        <div className="project" ref={modalRef}>
            <p>Project name - {project.name}</p>
            <p>Project desc - {project.description}</p>
            <p>Project created - {project.created_at}</p>
            <p>Project updated - {project.updated_at}</p>
        </div>
    );
}

export default ProjectModal;
