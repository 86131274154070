import { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../modules/axiosInstance';

export const useUserApp = () => {
    const [user, setUser] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const [activeTab, setActiveTab] = useState(() => {
        // Восстанавливаем активный таб из localStorage
        return parseInt(localStorage.getItem('activeTab'), 10) || 0;
    });
    const wsRef = useRef(null);
    const navigate = useNavigate();

    // Подключение WebSocket
    const connectWebSocket = useCallback((currentUser) => {
        if (!wsRef.current && currentUser && reconnectAttempts < 3) {
            console.log('Opening WebSocket connection');
            const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
            const wsHost = window.location.hostname === 'localhost' ? 'localhost:3030' : 'thebase-gup.ru';
            wsRef.current = new WebSocket(`${wsProtocol}://${wsHost}/ws/`);

            wsRef.current.onopen = () => {
                console.log('WebSocket connection opened');
                setReconnectAttempts(0);
            };
            wsRef.current.onerror = (error) => console.error('WebSocket error:', error);

            wsRef.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                switch (data.type) {
                    case 'news':
                        break;
                    case 'news-comment-add':
                        break;
                    case 'news-comment-delete':
                        break;
                    case 'notification':
                        if (data.targetGroups && data.targetGroups.includes(currentUser.group)) {
                        }
                        break;
                    case 'task_comments':
                        if (data.comment && data.comment.user_id !== currentUser.id) {
                            console.log(data.comment, data.comment.user_id, currentUser.id)
                            // Если комментарий не от текущего пользователя
                            if (data.comment.parent_comment_id === null || data.comment.parent_comment_id === data.targetCommentId) {
                                // Если это новый комментарий или ответ на тот, к которому относится целевой ID
                            }
                        }
                        break;
                    default:
                        break;
                }
            };

            wsRef.current.onclose = (event) => {
                console.log('WebSocket connection closed');
                if (!event.wasClean) {
                    console.error('Connection closed with error');
                    if (navigator.onLine && reconnectAttempts < 3) {
                        setReconnectAttempts((prev) => {
                            const newAttempts = prev + 1;
                            if (newAttempts < 3) {
                                setTimeout(() => connectWebSocket(currentUser), 3000);
                            } else {
                                console.error('Максимальное количество попыток переподключения исчерпано.');
                            }
                            return newAttempts;
                        });
                    }
                }
                wsRef.current = null;
            };
        }
    }, [reconnectAttempts]);

    // Восстановление пользователя при монтировании
    useEffect(() => {
        const savedUser = localStorage.getItem('user');
        if (savedUser) {
            const parsedUser = JSON.parse(savedUser);
            setUser(parsedUser);
            connectWebSocket(parsedUser);
        }
    }, [connectWebSocket]);

    useEffect(() => {
        if (user) {
            connectWebSocket(user);
        }
    }, [connectWebSocket, user]);

    // Сохранение активного таба
    const handleTabChange = (index) => {
        setActiveTab(index);
        localStorage.setItem('activeTab', index);

        // Меняем маршрут в зависимости от выбранной вкладки
        switch (index) {
            case 0:
                navigate('/news-page/');
                break;
            case 1:
                navigate('/search/');
                break;
            case 2:
                navigate('/top/');
                break;
            case 3:
                navigate('/stats/');
                break;
            case 4:
                navigate('/tasks/');
                break;
            case 5:
                navigate('/account-management/');
                break;
            case 6:
                navigate('/notification-management/');
                break;
            default:
                navigate('/');
                break;
        }
    };

    // Логин
    const login = async (username, rememberMe) => {
        try {
            const response = await axios.post('/login', { username });
            if (response.data.success) {
                const { token, user: loggedInUser } = response.data;

                setUser(loggedInUser);
                setErrorMessage('');

                // Сохраняем токен и пользователя в localStorage или sessionStorage
                if (rememberMe) {
                    localStorage.setItem('token', token);
                    localStorage.setItem('user', JSON.stringify(loggedInUser));
                } else {
                    sessionStorage.setItem('token', token);
                    sessionStorage.setItem('user', JSON.stringify(loggedInUser));
                }

                connectWebSocket(loggedInUser);
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            console.error('Login error:', error);
            setErrorMessage('Ошибка входа');
        }
    };

    // Логаут
    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
        localStorage.removeItem('activeTab');
        setUser(null);
        if (wsRef.current) {
            console.log('Closing WebSocket connection');
            wsRef.current.close();
            wsRef.current = null;
        }
    };

    return {
        user,
        errorMessage,
        login,
        logout,
        reconnectAttempts,
        wsRef,
        activeTab,
        handleTabChange
    };
};
