import axios from './axiosInstance';

export const fetchUsers = async (setUsers) => {
    try {
        const response = await axios.get('/users');
        setUsers(response.data);
    } catch (error) {
        console.error('Ошибка при получении пользователей:', error);
    }
};
export const getUserNameById = (id, users) => {
    const user = users.find(user => user.id === id);
    return user ? user.name : 'Неизвестный пользователь';
};