import React, { useState, useEffect, useCallback } from 'react';
import axios from './modules/axiosInstance';
import './styles/TopPage.css';
import { toast } from 'react-toastify';

function WeeklyStats({ user }) {
  const [weeklyStats, setWeeklyStats] = useState({});
  const [statistics, setStatistics] = useState([]);
  const [errorMessage] = useState('');
  const [nonEmptyColumns, setNonEmptyColumns] = useState([]);

  const fetchWeeklyStats = useCallback(async () => {
    try {
      const response = await axios.get('/weekly-stats', {
        params: { userId: user.id }
      });
      setWeeklyStats(response.data);
    } catch (error) {
      toast.error('Ошибка при получении статистики за неделю');
    }
  }, [user.id]);

  const fetchStatistics = useCallback(async () => {
    if (!user.user_group) {
      console.warn("User group is undefined, skipping fetchStatistics.");
      return;
    }

    try {
      const response = await axios.get('/statistics');
      const allData = response.data;

      let filteredData = [];
      if (user.user_group === 'Admin') {
        const adminStartIndex = allData.findIndex(row => row[0] === 'Администраторы');
        const visualStartIndex = allData.findIndex(row => row[0] === 'Визуализаторы');
        const nextSectionIndex = allData.findIndex(row => row[0] === 'Корректоры');
        filteredData = [
          ...allData.slice(adminStartIndex, visualStartIndex),
          ...allData.slice(visualStartIndex, nextSectionIndex)
        ];
      } else if (user.user_group === 'Proofreader') {
        const proofreaderStartIndex = allData.findIndex(row => row[0] === 'Корректоры');
        const nextSectionIndex = allData.findIndex(row => row[0] === 'Редакторы');
        filteredData = allData.slice(proofreaderStartIndex, nextSectionIndex);
      } else if (user.user_group === 'Editor') {
        const editorStartIndex = allData.findIndex(row => row[0] === 'Редакторы');
        const nextSectionIndex = allData.findIndex(row => row[0] === 'ВидеоАдмины');
        filteredData = allData.slice(editorStartIndex, nextSectionIndex);
      } else if (user.user_group === 'Videoadmin') {
        const videoAdminStartIndex = allData.findIndex(row => row[0] === 'ВидеоАдмины');
        const nextSectionIndex = allData.findIndex(row => row[0] === 'Видео-Монтажеры');
        filteredData = allData.slice(videoAdminStartIndex, nextSectionIndex);
      } else if (user.user_group === 'Montazh') {
        const montazhStartIndex = allData.findIndex(row => row[0] === 'Видео-Монтажеры');
        const nextSectionIndex = allData.findIndex(row => row[0] === 'Тестировка');
        filteredData = allData.slice(montazhStartIndex, nextSectionIndex);
      } else if (user.user_group === 'Test') {
        const TestStartIndex = allData.findIndex(row => row[0] === 'Тестировка');
        filteredData = allData.slice(TestStartIndex);
      }

      // Убираем ненужные столбцы (A, B, K, L)
      const finalData = filteredData.map(row => row.filter((_, index) => ![0, 1, 10, 11].includes(index)));

      // Определение непустых столбцов
      const nonEmptyColumns = [];
      for (let colIndex = 0; colIndex < finalData[0].length; colIndex++) {
        let hasData = false;
        for (let rowIndex = 1; rowIndex < finalData.length; rowIndex++) {
          if (finalData[rowIndex][colIndex]) {
            hasData = true;
            break;
          }
        }
        if (hasData) {
          nonEmptyColumns.push(colIndex);
        }
      }

      setNonEmptyColumns(nonEmptyColumns);
      setStatistics(finalData);
    } catch (error) {
      toast.error('Ошибка при загрузке общей статистики');
    }
  }, [user.user_group]);

  useEffect(() => {
    fetchWeeklyStats();
    fetchStatistics();
  }, [fetchWeeklyStats, fetchStatistics]);

  const renderWeeklyTableHeaders = () => {
    return user.statuses.map((status, index) => (
      <th key={index}>{status}</th>
    ));
  };

  const renderWeeklyTableData = () => {
    return Object.keys(weeklyStats).map((userName, index) => (
      <tr key={index}>
        <td>{userName}</td>
        {user.statuses.map((status, statusIndex) => (
          <td key={statusIndex}>{weeklyStats[userName][status] || 0}</td>
        ))}
      </tr>
    ));
  };

  const renderStatisticsTableData = () => {
    return statistics.slice(1).map((row, rowIndex) => (
      <tr key={rowIndex} className={row[0] && row[0].includes('Всего') ? 'total-row' : ''}>
        {nonEmptyColumns.map(colIndex => (
          <td key={colIndex}>{row[colIndex]}</td>
        ))}
      </tr>
    ));
  };

  return (
    <div className="stats-page">
      <h2>Статистика за неделю</h2>
      {errorMessage && <p className="error">{errorMessage}</p>}
      <div className="table-container-stats">
        <table>
          <thead>
            <tr>
              <th>Пользователь</th>
              {renderWeeklyTableHeaders()}
            </tr>
          </thead>
          <tbody>
            {renderWeeklyTableData()}
          </tbody>
        </table>
      </div>

      <h2>Общая статистика</h2>
      {errorMessage && <p className="error">{errorMessage}</p>}
      <div className="table-container-stats">
        <table>
          <thead>
            <tr>
              {nonEmptyColumns.map(colIndex => (
                <th key={colIndex}>{statistics[0][colIndex]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderStatisticsTableData()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WeeklyStats;
