import React, { useState } from 'react';
import axios from './modules/axiosInstance';
import './styles/style.css';
import { toast } from 'react-toastify';

function SearchPage({ user }) {
  const [cokInput, setCokInput] = useState('');
  const [cok, setCok] = useState('');
  const [eoms, setEoms] = useState([]);
  const [selectedEom, setSelectedEom] = useState(null);
  const [cokComment, setCokComment] = useState('');
  const [newCokComment, setNewCokComment] = useState('');
  const [comment, setComment] = useState('');
  const [newComment, setNewComment] = useState('');
  const [status, setStatus] = useState('');
  const [changeLog, setChangeLog] = useState([]);
  const [isChangeLogVisible, setIsChangeLogVisible] = useState(false);
  const [modificationDate, setModificationDate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [proofEditorData, setProofEditorData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingEom, setIsEditingEom] = useState(false);
  const [newEomName, setNewEomName] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAddingEom, setIsAddingEom] = useState(false);
  const [isSavingEom, setIsSavingEom] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingComEom, setIsEditingComEom] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [cokTaskId, setCokTaskId] = useState(null);

  const handleCokChange = (e) => {
    const input = e.target.value;
    setCokInput(input);
    if (input) {
      const extractedCok = input.split('-').slice(0, 3).join('-');
      setCok(extractedCok);
    } else {
      setCok('');
    }
  };

  const fetchEoms = async () => {
    if (!cok) {
      toast.error('Номер ЦОКа не введен');
      return;
    }

    setEoms([]);
    setSelectedEom(null);
    setStatus('');
    setComment('');
    setNewComment('');
    setModificationDate('');

    try {
      const response = await axios.get(`/search?cok=${cok}&userId=${user.id}`);
      if (response.data.length === 0) {
        toast.error('Номер ЦОКа не найден');
      } else {
        setEoms(response.data);
        setCokComment(response.data[0][6] || '');
        fetchProofEditorData(cok);

        const tasksResponse = await axios.get(`/bitrix-tasks?eomName=${cok}`);
        if (tasksResponse.data.length > 0) {
          setCokTaskId(tasksResponse.data[0].id);
        } else {
          setCokTaskId(null);
        }
      }
    } catch (error) {
      toast.error('Ошибка при получении ЭОМов');
    }
  };

  const fetchProofEditorData = async (cok) => {
    try {
      const response = await axios.get(`/proof-editor?cok=${cok}`);
      setProofEditorData(response.data);
    } catch (error) {
      toast.error('Ошибка при получении данных об ответственных');
    }
  };

  const updateCokComment = async () => {
    setIsUpdating(true);
    try {
      await axios.post('/update-cok-comment', {
        cok,
        newCokComment,
        userId: user.id
      });
      setCokComment(newCokComment);
      setIsEditing(false);
      setNewCokComment('');
      setErrorMessage('');
    } catch (error) {
      toast.error('Ошибка при обновлении комментария к ЦОКу');
    } finally {
      setIsUpdating(false);
    }
  };

  const updateEomComment = async () => {
    setIsUpdating(true);
    try {
      await axios.post('/update-comment', {
        cok,
        eom: selectedEom,
        newComment,
        userId: user.id
      });
      setComment(newComment);
      setIsEditingComEom(false);
      setNewComment('');
    } catch (error) {
      toast.error('Ошибка при обновлении комментария к ЭОМу');
    } finally {
      setIsUpdating(false);
    }
  };

  const updateStatus = async (newStatus) => {
    setIsLoading(true);
    try {
      await axios.post('/update-status', {
        cok,
        eom: selectedEom,
        newStatus,
        userId: user.id
      });
      setStatus(newStatus);
      const newDate = new Date().toLocaleDateString();
      setModificationDate(newDate);
      setEoms(eoms.map(eom => {
        if (eom[2] === selectedEom) {
          return [eom[0], eom[1], eom[2], newStatus, eom[4], newDate];
        }
        return eom;
      }));
      setErrorMessage('');
    } catch (error) {
      toast.error('Ошибка при обновлении статуса');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchChangeLog = async () => {
    try {
      if (!isChangeLogVisible) {
        const response = await axios.get('/changelog', {
          params: { userId: user.id }
        });
        setChangeLog(response.data);
        setErrorMessage('');
      }
      setIsChangeLogVisible(!isChangeLogVisible);
    } catch (error) {
      toast.error('Ошибка при получении журнала изменений');
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'Готов':
        return 'status ready';
      case 'На графике':
        return 'status graphic';
      case 'На видео':
        return 'status video';
      case 'На звуке':
        return 'status audio';
      case 'Отредактировано':
        return 'status editor';
      case 'У редактора':
        return 'status backeditor';
      case 'ТЗ подготовлено':
        return 'status tzready';
      case 'На монтаже':
        return 'status montazh';
      case 'Черновик':
        return 'status chernovyk';
      default:
        return 'status';
    }
  };

  const renderStatusButtons = () => {
    const statuses = user.statuses || [];

    return statuses.map((newStatus, index) => (
      <button
        className="button"
        key={index}
        onClick={() => updateStatus(newStatus)}
        disabled={isLoading || status === newStatus}
      >
        {newStatus}
      </button>
    ));
  };

  const handleEomSelection = async (eomName) => {
    setSelectedEom(eomName);

    setTaskId(null);
    setStatus('');
    setComment('');
    setModificationDate('');

    try {
      const cleanEomName = eomName.replace(' (остановлен)', '');
      const tasksResponse = await axios.get(`/bitrix-tasks?eomName=${cleanEomName}`);
      if (tasksResponse.data.length > 0) {
        setTaskId(tasksResponse.data[0].id);
      } else {
        setTaskId(null);
      }

      const eomData = eoms.find(eom => eom[2] === eomName);
      if (eomData) {
        setComment(eomData[4] || '');
      }
    } catch (error) {
      setTaskId(null);
    }
  };

  const handleEomEdit = async () => {
    if (!newEomName) return;

    setIsUpdating(true);
    try {
      await axios.post('/update-eom-name', {
        cok,
        oldEomName: selectedEom,
        newEomName,
        userId: user.id
      });
      setEoms(eoms.map(eom => {
        if (eom[2] === selectedEom) {
          return [eom[0], eom[1], newEomName, eom[3], eom[4], eom[5]];
        }
        return eom;
      }));
      setSelectedEom(newEomName);
      setIsEditingEom(false);
      setErrorMessage('');
    } catch (error) {
      toast.error('Ошибка при обновлении названия ЭОМа');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleAddEom = async () => {
    if (!newEomName) return;

    setIsSavingEom(true);

    try {
      const response = await axios.post('/add-eom', {
        cok,
        newEomName,
        userId: user.id
      });
      if (response.data === 'EOM added successfully') {
        setEoms([...eoms, [eoms.length + 1, cok, newEomName, '', '', '']]);
        setIsAddingEom(false);
        setNewEomName('');
      }
    } catch (error) {
      toast.error('Ошибка при добавлении нового ЭОМа');
    } finally {
      setIsSavingEom(false);
    }
  };

  return (
    <div className='search-page'>
      <div className='search'>
        <h2>Поиск по номеру ЦОК или ЭОМ</h2>
        <input
          type="text"
          value={cokInput}
          onChange={handleCokChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              fetchEoms();
            }
          }}
          placeholder="Введите номер ЦОК или ЭОМ"
        />
        <button className="button" onClick={fetchEoms}>Получить ЭОМы</button>
      </div>
      {errorMessage && <p className="error">{errorMessage}</p>}

      {eoms.length > 0 && (
        <div className='cok-comment'>
          <div>
            <span>ЦОК: <b>{cok}</b></span>
          </div>
          <div className='link_bitrix_cok'>
            {cokTaskId ? (
              <button
                onClick={() => window.open(`https://academymp.bitrix24.ru/company/personal/user/0/tasks/task/view/${cokTaskId}/`, '_blank')}
                className="bitrix-button"
              >
                Перейти в задачу ЦОКа
              </button>
            ) : (
              <span>Задача ЦОКа не найдена</span>
            )}
          </div>
          <div>
            {!cokComment ? (
              <div><p>Комментарий к ЦОКу:
                <input
                  type="text"
                  className="cok-comment-input"
                  value={newCokComment}
                  onChange={(e) => setNewCokComment(e.target.value)}
                  placeholder="Новый комментарий к ЦОК"
                />
                <button onClick={updateCokComment} disabled={isUpdating}>
                  Обновить комментарий
                </button>
              </p>
              </div>
            ) : (
              <div>
                <p>
                  Комментарий к ЦОКу: <b>{cokComment}</b>
                  <span
                    className="edit-icon"
                    onClick={() => {
                      setIsEditing(true);
                      setNewCokComment(cokComment);
                    }}
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                  >
                    ✏️
                  </span>
                </p>

                {/* Если активен режим редактирования, отображаем поле и кнопки */}
                {isEditing && (
                  <div className="cok-comment-edit">
                    <input
                      type="text"
                      className="cok-comment-input"
                      value={newCokComment}
                      onChange={(e) => setNewCokComment(e.target.value)}
                      autoFocus
                    />
                    <button className="button" onClick={updateCokComment} disabled={isUpdating}>
                      Сохранить
                    </button>
                    <button className="button" onClick={() => setIsEditing(false)}>
                      Отмена
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>

          {proofEditorData.length > 0 && (
            <div className='proof-editor-data'>
              {proofEditorData.map((row, index) => (
                <div key={index} className='proof-editor-row'>
                  <p><b>Корректор:</b> {row[1] || 'Нет данных'}</p>
                  <p><b>Редактор:</b> {row[2] || 'Нет данных'}</p>
                  <p><b>Разработчики:</b> {row[3] || 'Нет данных'}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      <div className="eom-list">
        {eoms.length > 0 && eoms.map((eom, index) => {
          const isStopped = eom[2].includes('(остановлен)');
          return (
            <button
              key={index}
              className={`eom-button ${selectedEom === eom[2] ? 'selected' : ''} ${isStopped ? 'stopped' : ''}`}
              onClick={() => handleEomSelection(eom[2])}
            >
              {eom[2]}
              <div className="status-wrapper">
                <span className={getStatusClass(eom[3])}>{eom[3]}</span>
              </div>
            </button>
          );
        })}
        {eoms.length > 0 && (
          <button className="button" onClick={() => setIsAddingEom(true)} disabled={isSavingEom}>
            Добавить новый ЭОМ
          </button>
        )}
        {isAddingEom && (
          <div className="add-eom-modal">
            <input
              type="text"
              placeholder="Введите название нового ЭОМа"
              value={newEomName}
              onChange={(e) => setNewEomName(e.target.value)}
            />
            <button className="button" onClick={handleAddEom} disabled={isSavingEom}>
              Сохранить
            </button>
            <button className="button" onClick={() => setIsAddingEom(false)} disabled={isSavingEom}>
              Отмена
            </button>
          </div>
        )}
      </div>

      {selectedEom && (
        <div className='selected-eom-container'>
          <div className='eom-header'>
            <b>Выбранный ЭОМ: </b>
              {isEditingEom ? (
                <>
                  <input
                    type="text"
                    value={newEomName}
                    onChange={(e) => setNewEomName(e.target.value)}
                  />
                  <button className="button" onClick={handleEomEdit} disabled={isUpdating}>Сохранить</button>
                  <button className="button" onClick={() => setIsEditingEom(false)} disabled={isUpdating}>Отмена</button>
                </>
              ) : (
                <b><u>{selectedEom}</u></b>
              )}
              <span
                className="edit-icon"
                onClick={() => {
                  setIsEditingEom(true);
                  setNewEomName(selectedEom);
                }}
              >✏️</span>
          </div>
          <div className='link_bitrix'>
            {taskId ? (
              <button
                onClick={() => window.open(`https://academymp.bitrix24.ru/company/personal/user/0/tasks/task/view/${taskId}/`, '_blank')}
                className="bitrix-button"
              >
                Перейти в задачу
              </button>
            ) : (
              <span>Задача не найдена</span>
            )}
          </div>
          <p>Статус: <span className={getStatusClass(status)}>{status}</span></p>
          <div className='status-button'>
            {renderStatusButtons()}
          </div>
          <div>
            <p>Дата изменения: <b>{modificationDate}</b></p>
          </div>

          <div>
            {!comment ? (
              <div>
                <p className='comment-add'>
                  Комментарий к ЭОМ:
                  <input
                    type="text"
                    className="eom-comment-input"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Новый комментарий к ЭОМ"
                  />
                  <button className="button" onClick={updateEomComment} disabled={isUpdating}>
                    Обновить комментарий
                  </button>
                </p>
              </div>
            ) : (
              <div>
                <p>
                  Комментарий к ЭОМ: <b>{comment}</b>
                  <span
                    className="edit-icon"
                    onClick={() => {
                      setIsEditingComEom(true);
                      setNewComment(comment);
                    }}
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                  >
                    ✏️
                  </span>
                </p>

                {isEditingComEom && (
                  <div>
                    <input
                      type="text"
                      className="eom-comment-input"
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      autoFocus
                    />
                    <button className="button" onClick={updateEomComment} disabled={isUpdating}>
                      Сохранить
                    </button>
                    <button className="button" onClick={() => setIsEditingComEom(false)}>
                      Отмена
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>


        </div>
      )}
      {user.role === 'manager' && (
        <div>
          <h4>Журнал изменений</h4>
          <button className="button" onClick={fetchChangeLog}>
            {isChangeLogVisible ? 'Скрыть журнал изменений' : 'Загрузить журнал изменений'}
          </button>
          {isChangeLogVisible && <ChangeLog changeLog={changeLog} />}
        </div>
      )}
    </div>
  );
}

function ChangeLog({ changeLog }) {
  return (
    <div className="change-log-wrapper">
      <div className="change-log">
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Пользователь</th>
                <th>ЦОК</th>
                <th>ЭОМ</th>
                <th>Тип изменения</th>
                <th>Новое значение</th>
                <th>Время</th>
              </tr>
            </thead>
            <tbody>
              {changeLog.map((log, index) => (
                <tr key={index}>
                  <td>{log.userName}</td>
                  <td>{log.cok}</td>
                  <td>{log.eom}</td>
                  <td><b>{log.changeType}</b></td>
                  <td><b>{log.newValue}</b></td>
                  <td>{new Date(log.timestamp).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SearchPage;
