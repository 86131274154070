import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Патч для удаления устаревших событий DOMNodeInserted в Quill
const originalAddEventListener = EventTarget.prototype.addEventListener;
EventTarget.prototype.addEventListener = function (type, listener, options) {
  if (type === 'DOMNodeInserted') {
    return; // Игнорируем добавление DOMNodeInserted
  }
  return originalAddEventListener.call(this, type, listener, options);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// или отправить на аналитический endpoint. Узнайте больше: https://bit.ly/CRA-vitals
reportWebVitals();
