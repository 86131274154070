import { toast } from "react-toastify";

const wsMessage = (ws, fetch) => {
  const handleMessage = (event) => {
    const data = JSON.parse(event.data);

    if (data.type === "notification") {
      // Обновление списка уведомлений
      fetch();
      // Обновление новостей
    } else if (data.type === "news") {
      // Показ toast уведомления о новости
      toast.info(`Добавлена новость: ${data.data.title}`, {
        // onClick: () => {
        //   toast.dismiss();
        //   handleModalToggle();
        // },
        style: { cursor: 'pointer' }
      });
      fetch();
    } else if (data.type === 'news-delete') {
      fetch();
      // Обновление комментов новостей
    } else if (data.type === 'news-comment-add') {
      fetch();
    } else if (data.type === 'news-comment-edited') {
      fetch();
    } else if (data.type === 'news-comment-delete') {
      fetch();
      // Обновление задач
    } else if(data.type === 'tasks-add') {
      fetch();
    } else if(data.type === 'tasks-edited') {
      fetch();
    }
  };

  if (ws) {
    ws.addEventListener("message", handleMessage);

    return () => {
      ws.removeEventListener("message", handleMessage);
    };
  }
};

export default wsMessage;